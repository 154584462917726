import React from "react"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import { StaticImage } from "gatsby-plugin-image"
import AllStories from "../components/LionsStories/AllStories"

const lionsStories = () => {
  return (
    <>
      <Seo pageTitle="All Lions Stories" 
      pageImageUrl={"/AllStories.jpg"}
      pageImageWidth={"2160"}
      pageImageHeight={"1440"}
      canonicalUri={"/stories"}      
      />
      <SimpleBanner title="All Lions Stories">
        <StaticImage
          className="banner__image"
          src="../images/AllStories.jpg"
          alt="All Lions Stories: circle of hands in collaboration."
        />
      </SimpleBanner>
      <AllStories/>
    </>
  )
}

export default lionsStories
