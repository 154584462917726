import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StoryStyles } from "./StoryStyles"
import FeatureStory from "./FeatureStory"

const getAllStories = graphql`
  query {
    stories: allContentfulLionsStory {
      edges {
        node {
          name
          excerpt
          contentful_id
          slug
          images {
            gatsbyImageData(width: 600, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`

const AllStories = () => {
  const response = useStaticQuery(getAllStories)
  const stories = response.stories.edges

  return (
    <StoryStyles>
      <div className="features__container">
        <div className="features__container--scroll">
          {stories.map(({ node }) => {
            return <FeatureStory key={node.contentful_id} feature={node} />
          })}
        </div>
      </div>
    </StoryStyles>
  )
}

export default AllStories
